<template>
    <div class="con-wrap">
        <DeleteHistoryPopup v-if="pop.isShow" @close="pop.isShow=false"></DeleteHistoryPopup>
        <CarrotTitle title="조직도 관리">
            드래그 앤 드랍으로 본부 또는 팀의 순서를 변경할 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="dp-table w-1200px">

                        <div class="float-left w-500px mr-25">

                            <div class="treeMove-area">
                                <div class="head">
                                    <span class="tit">본부/팀</span>
                                </div>
                                <div class="cont">
                                    <draggable
                                        tag="ul" 
                                        :list="mgr.tree" 
                                        class="treelist"
                                        handle=".handle"
                                        item-key="idx"
                                        @start="mgr.dragging = true"
                                        @end="mgr.dragging = false"
                                    >
                                        <template #item="{ element }">
                                        <li class="list" v-if="mgr.tree.length>0">
                                            <button class="btn-move handle"></button>
                                            <button class="btn-menubox" :class="{ 'active':element.idx==mgr.selected }" @click="mgr.doSelect(element.idx)">{{ element.kname }}</button><!--선택한 본부or팀 : active-->
                                            <button class="btn-menuAdd" @click="mgr.showAddTeam(element.idx)">+ 팀 추가</button>

                                            <CarrotTreeItem v-model="mgr.selected" :tree="element.children"></CarrotTreeItem>
                                            <ul class="treelist">
                                                <li class="list list-add" v-if="mgr.selected==element.idx&&mgr.show_add_team==true">
                                                    <button class="btn-move"></button>
                                                    <input type="text" class="input-menubox" v-model="mgr.add_name" placeholder="팀명을 입력해주세요.">
                                                    <button class="btn-default" @click="mgr.addTeam(element.idx)">추가</button>
                                                    <button class="btn-default" @click="mgr.selected=0;mgr.show_add_team=false">취소</button>
                                                </li>
                                            </ul>
                                        </li>
                                        </template>
                                    </draggable>

                                    <ul class="treelist">
                                        <!-- 본부 추가 : 팀or본부 추가시 li에 list-add추가 -->
                                        <li class="list list-add">
                                            <button class="btn-move"></button>
                                            <input type="text" class="input-menubox" placeholder="본부명을 입력해주세요." v-model="mgr.add_name">
                                            <button class="btn-menuAdd" @click="mgr.addOffice()">+ 본부 추가</button>
                                        </li>
                                        <!-- //본부 추가 -->
                                    </ul>

                                </div>
                            </div>

                            <div class="dp-table w-100per mt-40">
                                <button class="btn-default float-left" @click="pop.isShow=true">삭제이력</button>
                            </div>

                        </div>

                        <div class="float-right w-650px ml-25" v-if="mgr.selected>0">

                            <div class="headTeamM-fixtop txt-center" v-if="mgr.is_team=='Y'">
                                <span class="ver-middle mr-10">{{ mgr.info.office_name }}</span>
                                <input type="text" class="dp-inblock ver-middle w-100px" v-model="mgr.mod_name">
                                <span class="ver-middle ml-5">팀</span>
                            </div>
                            <table class="table-row" v-else>
                                <colgroup>
                                    <col width="150">
                                    <col width="500">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>본부/팀명</th>
                                        <td>
                                            <input type="text" class="w-300px" v-model="mgr.mod_name">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>변경내역</th>
                                        <td>
                                            <div class="overhidden overscroll maxh-110px">
                                                <p v-for="(irow, i) in mgr.info.histories.list" :key="i">{{ irow.log }} {{ irow.wdate }}</p>
                                                <p v-if="mgr.info.histories.total==0">변경내역이 없습니다.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="dp-table w-100per mt-20">
                                <button class="btn-default float-left" :disabled="mgr.isDisabled()" @click="mgr.del()">삭제</button>
                                <button class="btn-default float-right" @click="mgr.mod()">수정</button>
                            </div>
                            <div class="color-1 mt-10">※ 하위 팀이나 재직자가 있을 경우 삭제할 수 없습니다.</div>

                            <table class="table-col mt-40">
                                <colgroup>
                                    <col width="60">
                                    <col width="180">
                                    <col width="150">
                                    <col width="160">
                                    <col width="100">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>이름</th>
                                        <th>팀</th>
                                        <th>직급</th>
                                        <th>입사일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(irow, i) in mgr.list" :key="i">
                                        <td>{{ irow.num }}</td>
                                        <td><router-link :to="{ name:'myPIMS-PersonalInfoList_tab1-idx', params:{idx:irow.idx} }" target="_blank"><span class="btn-view">{{ irow.kname }}({{ irow.ename }})</span></router-link></td>
                                        <td>{{ irow.team_name }}</td>
                                        <td>{{ irow.position_name }}</td>
                                        <td>{{ irow.joindate }}</td>
                                    </tr>
                                    <tr v-if="mgr.total==0">
                                        <td colspan="5">본부/팀에 속한 재직자가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import Swal from 'sweetalert2';
import draggable from "vuedraggable";
import CarrotTreeItem from '@/components/common/CarrotTreeItem.vue';
import DeleteHistoryPopup from '@/components/popup/myPIMS/DeleteHistoryPopup.vue';
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",

    components: {
        draggable,
        CarrotTreeItem,
        DeleteHistoryPopup
    },

    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            isShow: false,
            idx   : 0
        });

        const mgr = reactive({
            isAuth   : false,
            tree     : [],
            idx      : 0,

            is_first : true,
            selected : 0,
            dragging : false,
            is_team  : "",

            show_add_team  : false,
            add_name       : "",
            mod_name       : "",

            info : { histories:{ list:[], total:0 } },
            list : [], total : 0,

            isDisabled : () => {
                return (mgr.info.has_children=='Y'||mgr.info.has_member=='Y')?true:false;
            },

            addOffice : () => {
                // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
                if( mgr.isAuth == false && store.state.pd_auth == false  ) {
                //if( mgr.isAuth == false ){
                    Swal.fire({
                        title : '조직도 관리',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                let params = {
                    kname : mgr.add_name
                };

                if( params.kname == "" ){
                    toast.error("본부명을 입력해주세요.");
                    return;
                }

                axios.post("/rest/mypims/addOffice", params).then((res) => {
                    if( res.data.err == 0 ){
                        mgr.doSearch();
                        mgr.add_name = "";
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showAddTeam : (idx) => {
                mgr.selected = idx;
                mgr.show_add_team = true;
                console.log(mgr.selected, mgr.show_add_team);
            },

            addTeam : (idx) => {
                if( mgr.isAuth == false ){
                    Swal.fire({
                        title : '조직도 관리',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                let params = {
                    pidx  : idx,
                    kname : mgr.add_name
                };

                if( params.kname == "" ){
                    toast.error("팀명을 입력해주세요.");
                    return;
                }

                axios.post("/rest/mypims/addTeam", params).then((res) => {
                    if( res.data.err == 0 ){
                        mgr.doSearch();
                        mgr.add_name = "";
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSelect : (idx) => {
                mgr.selected = idx;
            },

            mod : () => {
                if( mgr.isAuth == false ){
                    Swal.fire({
                        title : '조직도 관리',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                let params = {
                    idx   : mgr.selected,
                    kname : mgr.mod_name
                };

                if( !params.kname ){
                    toast.error("이름을 입력해 주세요.");
                    return;
                }

                axios.post("/rest/mypims/modDept", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title : '조직도 관리',
                            text  : '수정되었습니다.'
                        });
                        mgr.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            del : () => {
                if( mgr.isAuth == false ){
                    Swal.fire({
                        title : '조직도 관리',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                let params = {
                    idx  : mgr.selected
                };

                if( confirm("해당 본부 또는 팀을 삭제하시겠습니까?") == true ){
                    axios.post("/rest/mypims/delDept", params).then((res) => {
                        if( res.data.err == 0 ){
                            mgr.doSearch();

                            mgr.selected = 0;
                            mgr.is_team  = "";
                            mgr.info     = {};
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            doSubmit: (seq) => {
                if( mgr.isAuth == false ){
                    Swal.fire({
                        title : '조직도 관리',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                let params = {
                    seq : seq
                };

                axios.post("/rest/mypims/modSequence", params).then((res) => {
                    if( res.data.err == 0 ){
                        mgr.tree = res.data.tree;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {};

                axios.get("/rest/mypims/getTeamTree", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mgr.tree = res.data.tree;
                        if( mgr.is_first == true ){
                            mgr.selected = mgr.tree[0].idx;
                            mgr.is_first = false;
                        }
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                        // console.info(res.data.err_msg);
                    }
                });
            }
        });

        watch(() => mgr.selected, () => {
            let params = {
                idx : mgr.selected
            };

            axios.get("/rest/mypims/getDeptInfo", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                    mgr.list  = res.data.list;
                    mgr.total = res.data.total;
                    mgr.info  = res.data.info;

                    mgr.mod_name = mgr.info.kname;
                    mgr.is_team  = mgr.info.is_team;
                } else {
                    console.info(res.data.err_msg);
                }
            });
        });

        watch(() => mgr.dragging, (nVal, oVal) => {
            if( nVal == false && oVal == true ){
                let seq = [];
                let is_modified = false;
                for(let i=0; i<mgr.tree.length; i++){
                    let irow = mgr.tree[i];
                    seq.push(irow.idx);
                    if( (i+1) != irow.seq ){
                        is_modified = true;
                    }
                }
                if( is_modified == true ){
                    mgr.doSubmit(seq);
                }
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth == false ) {
                router.go(-1);
                Swal.fire({
                    title : '조직도 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            if( store.state.pd_auth == true ) {
                mgr.isAuth = true;
            }

            mgr.doSearch();
        });

        return {mgr, pop};
    }
}
</script>

<style lang="scss" scoped>
.btn-move-box {
    position:relative;
    float:right;
    width:30px;
    height:30px;
    .btn-up {
        position: absolute; top:0; right:5px; width:30px; height:15px; border-top-left-radius: 5px; border-top-right-radius: 5px; line-height:15px; padding:0; color:#aaa;
    }
    .btn-down {
        position:absolute; bottom:0; right:5px; width:30px; height:15px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; line-height:15px; padding:0; color:#aaa;
    }
    button:hover {
        background-color:#efefef;
    }
}
</style>